(<template>
  <div :class="{'in-progress': progressActive}"
       class="restore-pass-form is-progress-bar">
    <form class="restore-pass-form__form"
          @submit.prevent.stop="submitForm">
      <sk-input :description="newPassText"
                :placeholder="newPassText"
                :preselected-value="newPassword"
                :type="'password'"
                :margin-bottom="true"
                :icon="false"
                @fieldvaluechanged="setNewPassword" />
      <sk-input slot="passwordField"
                :description="confirmPassText"
                :placeholder="confirmPassText"
                :preselected-value="confirmPassword"
                :margin-bottom="true"
                :type="'password'"
                :icon="false"
                @fieldvaluechanged="setConfirmPassword" />
      <button :class="{'is-disabled': disableSubmit}"
              :disabled="disableSubmit"
              class="sk-btn sk-btn--default">{{ $gettext('Change my password') }}</button>
      <em class="restore-pass-form__note">{{ $gettext('8 characters minimum') }}</em>
    </form>
  </div>
</template>)

<script>
  export default {
    data() {
      return {
        newPassword: '',
        newPasswordErrors: [],
        newPasswordErrorsName: 'newPass',
        confirmPassword: '',
        confirmPasswordErrors: [],
        confirmPasswordErrorsName: 'confirmPass',
        progressActive: false
      };
    },
    computed: {
      newPassText() { return this.$gettext('New password'); },
      confirmPassText() { return this.$gettext('Confirm new password'); },
      disableSubmit() {
        return !this.newPassword.trim()
          || this.newPassword.trim().length < 8
          || this.confirmPassword.trim() !== this.newPassword.trim();
      }
    },
    methods: {
      setNewPassword(value) {
        this.newPassword = value;
      },
      setConfirmPassword(value) {
        this.confirmPassword = value;
      },
      submitForm() {
        this.newPasswordErrors = [];
        this.confirmPasswordErrors = [];

        if (!this.newPassword.trim()) {
          this.newPasswordErrors.push(this.$gettext('Please enter a new password'));
        } else if (this.newPassword.trim().length < 8) {
          this.emailValueErrors.push(this.$gettext('Your new password was too short (minimum is 8 characters)'));
        }
        if (this.confirmPassword.trim() !== this.newPassword.trim()) {
          this.confirmPasswordErrors.push(this.$gettext('Please check your new password and password confirmation'));
        }
        this.$store.commit('ErrorsStore/setError', {name: this.newPasswordErrorsName, errors: this.newPasswordErrors});
        this.$store.commit('ErrorsStore/setError', {name: this.confirmPasswordErrorsName, errors: this.confirmPasswordErrors});

        if (!this.disableSubmit) {
          const form = new FormData();

          form.append('identity[password]', this.newPassword.trim());

          this.progressActive = true;
          this.$store.dispatch('sendResetPass', {form, token: this.$route.query.token}).then((data) => {
            this.$store.dispatch('TTAuthStore/logIn', {token: data.token, uid: data.uid});
            this.$store.commit('UserInfoStore/setUserInfo', {userInfoObj: {uid: data.uid}});
            this.$store.dispatch('UserInfoStore/fetchUserInfo', data.uid).then(() => {
              // Google Tag Manager
              this.trackResetGA();
              // End Google Tag Manager
              if (this.$route.query.wizard) {
                this.$store.commit('UserInfoStore/setUserInfo', {userInfoObj: {wizard: true}});
              }
              this.progressActive = false;
              window.location.reload();
            });
          });
        }
      },
      trackResetGA() {
        this.$skGA.emitEvent({eventCategory: 'authentication', eventAction: 'reset', eventLabel: 'password', value: 0});
      }
    }
  };
</script>

<style scoped>
  .restore-pass-form {
    display: block;
    width: 100%;
    padding: 30px 0 55px;
  }

  .restore-pass-form__note {
    display: inline-block;
    width: 100%;
    margin-top: 15px;
    text-align: center;
  }
</style>
